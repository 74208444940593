import { defineNuxtRouteMiddleware, useNuxtApp, navigateTo } from "nuxt/app";
import { getRoute } from "~/lib/routes";

export default defineNuxtRouteMiddleware(async (to) => {
	const { $shopwareApiClient } = useNuxtApp();

	try {
		// Attempt to read the user context to check authentication
		const {
			data: { customer }
		} = await $shopwareApiClient.invoke("readContext get /context");
		const isLoggedIn = customer && customer.id;

		// is not logged in
		if (!isLoggedIn) {
			if (to.path.includes("/bestellen")) {
				return navigateTo(getRoute("checkoutLogin"));
			}

			return navigateTo(getRoute("accountLogin"));
		}
	} catch {
		// If authentication fails, redirect to the login page
		return navigateTo(getRoute("accountLogin"));
	}
});
